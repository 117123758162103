import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import App from './App';
import './index.css';
import Home from './pages/home/home';
import NewsDetails from './pages/news/NewsDetails';
import NewsList from './pages/news/NewsList';
import reportWebVitals from './reportWebVitals';
import ServiceWork from './ServiceWork';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        {process.env.REACT_APP_SERVICE_WORK === 'true' ? (
          <Route path='/' element={<ServiceWork />}>
            <Route path='*' element={<Navigate to={""} replace />} />
          </Route>
        ) : (
          <Route path='/' element={<App />}>
            <Route path='/' element={<Home />} />
            <Route path='*' element={<Navigate to={"/"} replace />} />
            <Route path='/news' element={<NewsList />} />
            <Route path='/news/:newsId' element={<NewsDetails />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
