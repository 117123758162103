import { motion } from "framer-motion";
import { Cursor, useTypewriter } from "react-simple-typewriter";

const brand = require('./assets/images/brand.png');

const ServiceWork = () => {
    const [message] = useTypewriter({
        words: ["Wir bauen aktuell um!"],
        loop: true,
        typeSpeed: 100,
        deleteSpeed: 70,
        delaySpeed: 3000
    });

    return (
        <div className="h-screen flex flex-col justify-center items-center">
            <motion.h1
                className="font-poppins font-bold text-xl text-gray-700 uppercase tracking-wider"
                initial={{ y: -500, opacity: 0, scale: 0.5 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
            >
                Ortsfeuerwehr
                <span className="text-red-700">Braz</span>
            </motion.h1>
            <motion.div
                className="py-16"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
            >
                <img src={brand} alt="Logo" />
            </motion.div>
            <motion.h2
                className="font-poppins font-regular text-md text-gray-700"
                initial={{ y: 500, opacity: 0, scale: 0.5 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
            >
                <span>{message}</span>
                <Cursor />
            </motion.h2>
        </div>
    );
};

export default ServiceWork;