import { motion } from "framer-motion";
import { Cursor, useTypewriter } from "react-simple-typewriter";

const brandImage = require('../../../assets/images/brand.png');

const Brand = () => {
    const [title] = useTypewriter({
        words: ["Unsere Freizeit", "Für Ihre Sicherheit"],
        loop: true,
        typeSpeed: 100,
        deleteSpeed: 70,
        delaySpeed: 2500
    });

    return (
        <motion.div
            className="h-screen flex flex-col justify-center items-center space-y-4"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
        >
            <img src={brandImage} alt="Logo" className="h-24 md:h-28" />
            <h2 className="font-poppins font-medium text-md md:text-lg text-red-700">
                <span>{title}</span>
                <Cursor />
            </h2>
        </motion.div>
    )
};

export default Brand;