import { useNavigate } from "react-router-dom";
import Page from "../Page";

interface NewsItem {
    id: number,
    thumbnail: string,
    title: string,
    createDate: Date
};

const news: NewsItem[] = [
    {
        id: 1,
        thumbnail: require('../../assets/images/news/Friedenslicht.jpg'),
        title: 'Friedenslichtaktion',
        createDate: new Date(2022, 10, 23)
    },
    {
        id: 2,
        thumbnail: require('../../assets/images/news/Jahreshauptversammlung_2020.jpg'),
        title: 'Jahreshauptversammlung',
        createDate: new Date(2022, 9, 2)
    },
    {
        id: 3,
        thumbnail: require('../../assets/images/news/Waldbrand.jpg'),
        title: 'Waldbrand Lötsch',
        createDate: new Date(2022, 4, 20)
    },
    {
        id: 4,
        thumbnail: require('../../assets/images/news/Jahreshauptversammlung_2021.jpg'),
        title: 'Jahreshauptversammlung',
        createDate: new Date(2021, 10, 23)
    },
    {
        id: 5,
        thumbnail: require('../../assets/images/news/Wohnwagenbrand.jpg'),
        title: 'Wohnwagenbrand Traube',
        createDate: new Date(2021, 5, 19)
    }
];

type Props = {}

export default function NewsList({ }: Props) {
    const navigate = useNavigate();

    return (
        <Page title="Alle Neuigkeiten" hasPreviousNavigation={true}>
            <div className="w-full px-4 overflow-y-scroll">
                {news.map((value) => (
                    <div
                        key={value.id}
                        className="shadow h-48 w-full bg-white my-4 p-4 rounded-md flex flex-col justify-center items-center"
                        onClick={() => navigate(`/news/${value.id}`)}
                    >
                        <img src={value.thumbnail} alt="" className="h-28 mb-2" />
                        <h3 className="font-poppins font-regular text-gray-600 text-sm">{value.title}</h3>
                        <h4 className="font-poppins font-light text-gray-500 text-xs">{value.createDate.toLocaleDateString()}</h4>
                    </div>
                ))}
            </div>
        </Page>
    );
};