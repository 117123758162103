import React, { useEffect, useState } from "react";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import Page from "../../Page";

interface NewsItem {
    id: number,
    thumbnail: string,
    title: string,
    createDate: Date
};

const news: NewsItem[] = [
    {
        id: 1,
        thumbnail: require('../../../assets/images/news/Friedenslicht.jpg'),
        title: 'Friedenslichtaktion',
        createDate: new Date(2022, 10, 23)
    },
    {
        id: 2,
        thumbnail: require('../../../assets/images/news/Jahreshauptversammlung_2020.jpg'),
        title: 'Jahreshauptversammlung',
        createDate: new Date(2022, 9, 2)
    },
    {
        id: 3,
        thumbnail: require('../../../assets/images/news/Waldbrand.jpg'),
        title: 'Waldbrand Lötsch',
        createDate: new Date(2022, 4, 20)
    },
    {
        id: 4,
        thumbnail: require('../../../assets/images/news/Jahreshauptversammlung_2021.jpg'),
        title: 'Jahreshauptversammlung',
        createDate: new Date(2021, 10, 23)
    },
    {
        id: 5,
        thumbnail: require('../../../assets/images/news/Wohnwagenbrand.jpg'),
        title: 'Wohnwagenbrand Traube',
        createDate: new Date(2021, 5, 19)
    }
];

const News = () => {
    const [activeNews, setActiveNews] = useState(news[0]);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            moveNewsForward(undefined);
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    });

    const moveNewsForward = (event?: React.MouseEvent<HTMLElement>) => {
        event?.stopPropagation();
        const currentIndex = news.findIndex(x => x.id === activeNews.id);
        if (currentIndex === (news.length - 1)) {
            return setActiveNews(news[0]);
        }

        setActiveNews(news[currentIndex + 1]);
    };

    const moveNewsBackward = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const currentIndex = news.findIndex(x => x.id === activeNews.id);
        if (currentIndex === 0) {
            return setActiveNews(news[news.length - 1]);
        }

        setActiveNews(news[currentIndex - 1]);
    };

    const lastNews = () => {
        return (
            <div className="text-center">
                <p className="px-4 pb-4 font-poppins text-xs text-end font-light text-gray-600">
                    <Link to={"news"}>
                        Alle Neuigkeiten anzeigen
                    </Link>
                </p>
                <div onClick={() => navigate(`/news/${activeNews.id}`)}>
                    <div className="w-screen h-48 mb-4 relative">
                        <img src={activeNews.thumbnail} alt="Thumbnail" className="w-full h-full object-cover absolute" />
                        <div className="absolute w-full h-full px-2 flex justify-between items-center">
                            <div className="w-8 h-8 p-2 bg-white rounded-full z-30" onClick={moveNewsBackward}>
                                <HiArrowLeft className="w-full h-full text-gray-600" />
                            </div>
                            <div className="w-8 h-8 p-2 bg-white rounded-full z-30" onClick={moveNewsForward}>
                                <HiArrowRight className="w-full h-full text-gray-600" />
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <h3 className="font-poppins font-medium text-gray-600 text-md">{activeNews.title}</h3>
                        <p className="font-poppins font-light text-gray-500 text-sm">{activeNews.createDate.toLocaleDateString()}</p>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    {news.map((value) => (
                        <div
                            key={value.id}
                            className={"w-1.5 h-1.5 rounded-full mx-0.5 " + (value.id === activeNews.id ? "bg-gray-500" : "bg-gray-300")}
                        ></div>
                    ))}
                </div>
            </div>
        );
    }

    const socialMedia = () => {
        return (
            <div className="text-center px-4">
                <h3 className="font-poppins font-regular text-gray-600 text-sm mb-4">
                    Folge uns gerne auf Social Media um auf dem Laufenden zu bleiben
                </h3>
                <div>
                    <SocialIcon
                        className="shadow rounded-full mx-4"
                        style={{ height: 40, width: 40 }}
                        url='https://www.facebook.com/ortsfeuerwehrbraz/'
                        bgColor='transparent'
                        fgColor='#374151'
                    />
                    <SocialIcon
                        className="shadow rounded-full mx-4"
                        style={{ height: 40, width: 40 }}
                        url='https://www.instagram.com/feuerwehrbraz/'
                        bgColor='transparent'
                        fgColor='#374151'
                    />
                </div>
            </div>
        );
    };

    return (
        <Page title="Aktuelles">
            {lastNews()}
            {socialMedia()}
        </Page>
    );
};

export default News;