import Page from "../Page";

interface NewsItem {
    id: number,
    images: string[],
    title: string,
    createDate: Date
};

const news: NewsItem[] = [
    {
        id: 1,
        images: [
            require('../../assets/images/news/Friedenslicht.jpg'),
            require('../../assets/images/news/Jahreshauptversammlung_2020.jpg'),
            require('../../assets/images/news/Waldbrand.jpg'),
            require('../../assets/images/news/Jahreshauptversammlung_2021.jpg'),
            require('../../assets/images/news/Wohnwagenbrand.jpg')
        ],
        title: 'Friedenslichtaktion',
        createDate: new Date(2022, 10, 23)
    },
    {
        id: 2,
        images: [
            require('../../assets/images/news/Jahreshauptversammlung_2020.jpg'),
            require('../../assets/images/news/Jahreshauptversammlung_2021.jpg')
        ],
        title: 'Jahreshauptversammlung',
        createDate: new Date(2022, 9, 2)
    },
    {
        id: 3,
        images: [
            require('../../assets/images/news/Wohnwagenbrand.jpg'),
            require('../../assets/images/news/Waldbrand.jpg'),
            require('../../assets/images/news/Friedenslicht.jpg'),
        ],
        title: 'Waldbrand Lötsch',
        createDate: new Date(2022, 4, 20)
    },
    {
        id: 4,
        images: [
            require('../../assets/images/news/Jahreshauptversammlung_2021.jpg')
        ],
        title: 'Jahreshauptversammlung',
        createDate: new Date(2021, 10, 23)
    },
    {
        id: 5,
        images: [
            require('../../assets/images/news/Friedenslicht.jpg'),
            require('../../assets/images/news/Jahreshauptversammlung_2021.jpg'),
            require('../../assets/images/news/Wohnwagenbrand.jpg'),
            require('../../assets/images/news/Waldbrand.jpg'),
        ],
        title: 'Wohnwagenbrand Traube',
        createDate: new Date(2021, 5, 19)
    }
];

type Props = {}

export default function NewsDetails({ }: Props) {
    return (
        <Page title="Details" hasPreviousNavigation={true}>

        </Page>
    );
};